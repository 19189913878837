@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;200;300;400;500;600;700;800&display=swap');

body{
    @apply font-inter-tight;
}

html {
    overflow-y:scroll;
}